export default {
  methods: {
    bucketImage(source, widthInPx, quality0to100) {
      let widthSegment = widthInPx ? 'w' + widthInPx : ''
      let quality = quality0to100 ? 'q' + quality0to100 : ''
      let pathBucket = /bucket[a-z]?[0-9]?\.glanacion(.+)?\.(jpg|jpeg|png|gif)/i
      let pathReplacer = /\.(jpg|jpeg|png|gif)/i
      let isBucket = pathBucket.test(source)
      let sourceParsed = isBucket
        ? source.replace(pathReplacer, widthSegment + quality + '.$1')
        : source
      return sourceParsed.replace('http:', 'https:')
    }
  }
}
