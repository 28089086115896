<template>
  <div id="divLogos">
    <p>Desarrollado por la nacion en alianza con:</p>
    <div id="logos">
      <!-- <img
        src="@/assets/logo-dir-legislativo.jpg"
        alt="Directorio Legislativo"
      />
      <img
        src="@/assets/logo-ecohouse.jpg"
        alt="Ecohouse"
      />
      <img
        src="@/assets/logo-vida-silvestre.jpg"
        alt="Vida Silvestre"
      />
      <img
        src="@/assets/logo-farn.jpg"
        alt="FARN"
      /> -->
      <img
        src="https://buckets3.glanacion.com/lntools/anexos/fotos/83/32183.jpg"
        alt="Directorio Legislativo"
      />
      <img
        src="https://buckets3.glanacion.com/lntools/anexos/fotos/84/32184.jpg"
        alt="Ecohouse"
      />
      <img
        src="https://buckets3.glanacion.com/lntools/anexos/fotos/86/32186.jpg"
        alt="FARN"
      />
      <img
        src="https://buckets3.glanacion.com/lntools/anexos/fotos/85/32185.jpg"
        alt="Vida Silvestre"
      />
    </div>
  </div>
</template>

<script>
  //   import Redes from './Redes.vue'

  export default {
    name: 'Logos',
    props: {
      //msg: String
    },
    components: {
      //   Redes
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/styles/_variables.scss';
  #divLogos {
    width: 100%;
    max-width: 740px;
    margin: 0 auto 50px;
    text-align: center;
    padding: 50px 0;
    font-family: $suecaslabsemibold;
    text-transform: uppercase;
    font-size: 0.8rem;
    border-top: 1px solid #707070;
    p {
      font-size: 14px;
      text-transform: uppercase;
      font-family: $suecaslabsemibold;
      margin: 10px auto;
    }
    #logos {
      width: 70%;
      margin: auto;
      img {
        margin: 20px 1%;
        width: calc(21% - 5px);
        vertical-align: middle;
      }
    }
    @media screen and (max-width: 640px) {
        #logos {
          width: 80%;
        }
    }
    @media screen and (max-width: 500px) {
        p{
          font-size: 11px;
          width: 90%;
        }
        #logos {
          width: 100%;
        }
      }
  }
</style>
