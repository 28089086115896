<template>
  <div id="appFooterStatic">
    <div class="title-creditos">CRÉDITOS</div>
    <div
      class="conte-creditos"
      :style="'gridTemplateColumns: repeat(' + integrantes.length + ', 1fr)'"
    >
      <div
        v-for="(grupo, index) in integrantes"
        :key="index"
        class="columna-categorias"
      >
        <div class="categoria-title">{{ grupo.categoria }}</div>
        <ul>
          <li v-for="(personas, count) in grupo.personas" :key="count">
            {{ personas }}
          </li>
        </ul>
      </div>
    </div>

    <Copyright />
    <Redes
      :textoRedes="'Monitor Legislativo Ambiental: ¿qué tan cerca están los proyectos de convertirse en ley? Una plataforma para seguir el pulso de la agenda ambiental en el Congreso. @LNnaturaleza @fundlanacion'"
      :hashtag="'MonitorLegislativoAmbiental'"
    ></Redes>
  </div>
</template>

<script>
import creditos from '@/data/creditos'
import Copyright from './Copyright.vue'
import Redes from './Redes'

export default {
  name: 'FooterStatic',
  props: {
    //msg: String
  },
  data() {
    return {
      integrantes: creditos
    }
  },
  components: {
    Copyright,
    Redes
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import '@/styles/_variables.scss';

#appFooterStatic {
  width: 100%;
  background-color: #000;
  padding-bottom: 10px;
  color: #fff;

  .title-creditos {
    text-align: center;
    font-size: 20px;
    font-family: $suecaslabthin;
    line-height: 50px;
    padding-top: 20px;
    color: #fff;
    border-bottom: 1px solid #fff;
    max-width: 600px;
    margin: auto;
  }

  .conte-creditos {
    display: grid;
    // width: 100%;
    max-width: 800px;
    justify-content: center;
    margin: 0 auto;
    flex-wrap: wrap;

    @media (max-width: 640px) {
      display: block;
    }

    div.columna-categorias {
      padding: 0;
      text-align: center;

      div.categoria-title {
        font-family: $suecaslabbold;
        margin-top: 25px;
        margin-bottom: 8px;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
          line-height: 1.2;
        }
      }
    }
  }

  div#copyright {
    text-align: center;
    margin: 20px 0;
    padding: 20px;
    text-transform: uppercase;
    line-height: 1.4;
    color: #fff;
  }
  @media screen and (max-width: 600px) {
    .conte-creditos{
      div.columna-categorias {
        div.categoria-title {
          width: 90%;
          margin: auto;
          margin-top: 25px;
          margin-bottom: 8px;
        }
        ul{
          margin: auto;
          width: 90%;
        }
      }
    } 
  }
}
</style>
