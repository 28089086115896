<template>
  <div id="tema">
    <div v-for="(item, index) in temas" :key="index" :id="ids(item)">
        <div class="encabeza" :style=colorFondo(item)>
            <h2 class="tituTema" :style=colorTitulo(item)>{{ item.nombre }}</h2>
            <p class="desc" :style=colorTitulo(item)>{{ item.descripcion }}</p>
        </div>
        <div class="tri" :style=colorTri(item)></div>
        <p class="tituEstado">ESTADO LEGISLATIVO</p>
        <h2 class="nomEstado">{{ tituEstado(item) }}</h2>
        <div class="graf">
            <p class="tiempo" :class="{'no':seeCaduca(item.tiempo), 'up':item.estadoNumero >= 20 ? true : false, 'noEs':item.estadoNumero == 1 || item.estadoNumero == 7 || item.estadoNumero == 12 || item.estadoNumero == 13 || item.estadoNumero >= 20 ? true : false}">TIEMPO<br>DE<br>CADUCIDAD<br><span>{{ getDia(item.tiempo) }}</span><br>DÍAS</p>
            <img
                class="circulo lazy"
                v-lazy="circulos(item)"
                alt="Estado legislativo"
            />
        </div>
        <h3 class="tituH3" v-if="item.textos.porqueImportante">¿Por qué es importante que haya una ley de este tema?</h3>
        <p class="porque tex" v-if="item.textos.porqueImportante">{{ item.textos.porqueImportante }}</p>
        <h3 class="tituH3" v-if="item.textos.cronologia">Cronología del tema</h3>
        <p class="crono tex" v-if="item.textos.cronologia">{{ item.textos.cronologia }}</p>
        <h3 class="tituH3" v-if="item.textos.estatusLegislativo">Descripción del estado legislativo</h3>
        <p class="estatus tex" v-if="item.textos.estatusLegislativo">{{ item.textos.estatusLegislativo }}</p>
        <h3 class="tituH3" v-if="item.textos.proximoPaso">Próximo paso: qué tiene que suceder para lograr que se sancione</h3>
        <p class="proxpaso tex" v-if="item.textos.proximoPaso">{{ item.textos.proximoPaso }}</p>
        <h3 class="tituH3">Los proyectos más destacados</h3>
        <div class="titusTabla">
            <p class="tiTa">NOMBRE</p>
            <p class="tiTa">Nº EXPEDIENTE</p>
            <p class="tiTa">AUTOR</p>
            <p class="tiTa">LINK</p>
            <p class="tiTa">VIGENTE</p>
        </div>
        <ul :id="idsTablaProy(item)" class="tablaProy" :class="{'active':abre}">
            <li class="proy" v-for="(itemT, indexT) in temasFiltered(index)" :key="indexT">
                <p class="nom">{{ itemT.proyecto }}</p>
                <p class="exped">{{ itemT.numeroExpediente }}</p>
                <!-- <p class="autor">{{ itemT.autor }}</p> -->
                <a v-bind:href="itemT.linkFichaAutor" class="autor" target="_blank" :class="{'ver':seeLink(itemT.linkFichaAutor)}">{{ itemT.autor }}</a>
                <a v-bind:href="itemT.linkTabla" class="link" target="_blank" :class="{'ver':seeLink(itemT.linkTabla)}">Ver proyecto</a>
                <p class="vigente">{{ vigencia(itemT) }}</p>
            </li>
        </ul>
        <div id="botVerMas" class="bot" :class="{'active':seeBot(index)}" @click="setBotones(index)">
            <p>{{ textBot(index) }}</p>
        </div>
        <a v-bind:href="item.links.mediaSancion" target="_blank" class="linkTextos" v-if="item.links.mediaSancion">Ver texto de media sanción</a>
        <a v-bind:href="item.links.dictamen" target="_blank" class="linkTextos" v-if="item.links.dictamen">Ver texto del dictamen</a>
        <a v-bind:href="item.links.ley" target="_blank" class="linkTextos" v-if="item.links.ley">Ver texto de ley</a>
        <a v-bind:href="item.links.cobertura" target="_blank" class="linkTextos cober">Ver toda la cobertura en LA NACION</a>
        <!-- <a href="https://www.lanacion.com.ar/" target="_blank" class="linkTextos">Qué puedo hacer al respecto</a> -->
        <Logos />
    </div>
  </div>
</template>

<script>
  //    import librerias from './librerias.js';
  import Vue from 'vue'
  import Logos from './Logos.vue'
  import bucketImage from '@/mixins/bucketImage.js'
  import urlLn from '@/mixins/urlLn'
  import dicc from '@/data/dicc.js'
  import moment from 'moment'
  import Axios from 'axios'

  moment.locale('es')
  let txtEstados = 'https://olcreativa.lanacion.com.ar/dev/get_url/api.php?key2=1z4Cn9n-LhfJb_dPeRQXQZuskYxl4t8QPTcV6PoDs-1w&gid=134603724'

//   const jsonTemas = 'https://s3.us-east-1.amazonaws.com/especiales.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'
//   const jsonTemas = 'https://especialess3.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'

  export default {
    name: 'Descripcion',
    mixins: [bucketImage, urlLn],
    props: ["temas"],
    components: {
      Logos
    },
    mounted() {
        this.fetchData();
    },
    data(){
        return {
            abre: false,
            numEstados: null,
            textAbreTabla: "Ver más",
            botones: []
        };
    },
    computed: {
        temasOrden() {
            let ordenado = Object.values(this.temas)
            return ordenado.sort(function(a, b) {
                if (parseInt(a.estadoNumero) > parseInt(b.estadoNumero)) {
                    return -1;
                }
                if (parseInt(a.estadoNumero) < parseInt(b.estadoNumero)) {
                    return 1;
                }
                return 0;
            });
        }
    },
    methods: {
        fetchData(){
            let requestDatos1 = Axios.get(txtEstados);
            Axios.all([requestDatos1])
            .then(
            Axios.spread((...responses) => {
                this.numEstados = responses[0].data;
            })
            )
            .catch(errors => {
            // react on errors.
            console.log(errors);
            });
        },
        ids(item){
            let nom = item.nombre
            let nomIds = nom.replace(/[áéíóúüñ-\s\(\)\(.)\(+)\(*)\(,)/]/g, '').toLowerCase();
            return nomIds
        },
        idsTablaProy(item){
            let nom = item.nombre
            let nomIds = nom.replace(/[áéíóúüñ-\s\(\)\(.)\(+)\(*)\(,)/]/g, '').toLowerCase();
            return "proy-" + nomIds
        },
        // changeAbreTabla() {
        //     let idAbrir = this
        //     console.log(idAbrir)
        //     this.abre = this.abre === true ? false : true;
        //     this.textAbreTabla = this.textAbreTabla === "Ver más" ? "Cerrar" : "Ver más"
        // },
        textBot(index) {
            if (this.botones[index]) {
                return "Cerrar"
            } else {
                return "Ver más"
            }
        },
        seeBot(index) {
            // let tabla = this.temas[index].tabla
            // console.log("TABAL", tabla)
            if ((this.temas[index].tabla).length > 3) {
                return true
            }
        },
        temasFiltered(index) {
            if (this.botones[index]) {
                return this.temas[index].tabla
            } else {
                return this.temas[index].tabla.slice(0, 3)
            }
        },
        setBotones(index) {
            // console.log("CLICKKKKK ", index)
            // console.log("ANTES ", this.botones[index])
            // this.abre = this.abre === true ? false : true;
            // this.textAbreTabla = this.textAbreTabla === "Ver más" ? "Cerrar" : "Ver más"
            Vue.set(this.botones, index, !this.botones[index])
            // console.log("DESPUES ", this.botones[index])
        },
        circulos(item) {
            let ancho = window.innerWidth
            let imag
            if (item.estadoNumero == 100 || item.estadoNumero == 22) {
                item.estadoNumero = "12"
            } else if (item.estadoNumero == 15) {
                item.estadoNumero = '14'
            } 
            if (ancho > 640) {
                if (item.camaraOrigen == 'Diputados') {
                    imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/estados/diputados/" + item.estadoNumero + "_DESKTOP_DIPUTADOS.svg?v=2"
                } else {
                    imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/estados/senado/" + item.estadoNumero + "_DESKTOP_SENADO.svg?v=2"
                }
                return imag
            } else {
                if (item.camaraOrigen == 'Diputados') {
                    imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/estadosMobile/diputados/" + item.estadoNumero + "_MOBILE_DIPUTADOS.svg?v=2"
                } else {
                    imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/estadosMobile/senado/" + item.estadoNumero + "_MOBILE_SENADO.svg?v=2"
                }
                return imag
            }
        },
        seeCaduca(tiempo) {
            // console.log(this.getDia(tiempo))
            if (this.getDia(tiempo) <= 0) {
                return true
            }
        },
        getDia(fecha) {
            let hoy = moment().format("DD/MM/YYYY")
            let futuro = fecha
            let cadu = moment(futuro, "DD/MM/YYYY").diff(moment(hoy, "DD/MM/YYYY"), 'days')
                return cadu
            // if (cadu > 0) {
            //     return cadu
            // } else {
            //     return '0'
            // }
        },
        seeLink(link) {
            if (link) {
                return true
            }
        },
        vigencia(item) {
            if (item.vigente == 1) {
                return "SI"
            } else if (item.vigente == 0) {
                return "NO"
            } else {
                return "-"
            }
        },
        tituEstado(item) {
                // console.log("ANTES", item.estadoNumero)
            if (item.estadoNumero == '100' || item.estadoNumero == '22') {
                item.estadoNumero = "12"
            } else if (item.estadoNumero == '15') {
                item.estadoNumero = '14'
            } 
                // console.log("DESP", item.estadoNumero)
            let nom = this.numEstados.filter((p) => p.estadoLegislativo === item.estadoNumero);
            // console.log(nom[0].tituEstado)
            return nom[0].nombrefront
        },
        colorFondo(item) {
            if (item.estadoNumero == '100' || item.estadoNumero == '22') {
                item.estadoNumero = "12"
            } else if (item.estadoNumero == '15') {
                item.estadoNumero = '14'
            } 
            let n = this.numEstados.filter((p) => p.estadoLegislativo === item.estadoNumero);
            return 'backgroundColor:' + n[0].color
        },
        colorTitulo(item) {
            // let n = dicc.filter((p) => p.estadoNumero === item.estadoNumero);
            // console.log(n[0].color)
                // console.log("ANTES", item.estadoNumero)
            if (item.estadoNumero == '100' || item.estadoNumero == '22') {
                item.estadoNumero = "12"
            } else if (item.estadoNumero == 15) {
                item.estadoNumero = '14'
            } 
                // console.log("DESP", item.estadoNumero)
            if (item.estadoNumero == '7' || item.estadoNumero == '13') {
                return 'color:#fff'
            } else {
                return 'color:#000'
            }
        },
        colorTri(item) {
                // console.log("ANTES", item.estadoNumero)
            if (item.estadoNumero == '100' || item.estadoNumero == '22') {
                item.estadoNumero = "12"
            } else if (item.estadoNumero == '15') {
                item.estadoNumero = '14'
            } 
                // console.log("DESP", item.estadoNumero)
            let n = this.numEstados.filter((p) => p.estadoLegislativo === item.estadoNumero);
            return 'borderTop: 18px solid ' + n[0].color
        }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/_global.scss';

  #tema {
    margin: auto;
    text-align: center;
    .encabeza {
        width: 100%;
        background-color: #BFCE8D;
        text-align: center;
        padding: 30px 0;
        position: relative;
        margin-bottom: 7px;
        .tituTema{
            font-family: $suecaslabbold;
            font-size: 35px;
            line-height: 45px;
            margin: 20px auto;
        }
        .desc{
            font-family: $suecaslabmedium;
            font-size: 18px;
            line-height: 25px;
            margin: 20px auto;
            width: 70%;
        }
        // &:after{
        //     content: '';
        //     position: absolute;
        //     left: 49%;
        //     bottom: -25px;
        //     width: 0;
        //     height: 0;
        //     border-left: 12px solid transparent;
        //     border-right: 12px solid transparent;
        //     border-top: 18px solid #BFCE8D;
        // }
    }
    .tri{
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-top: 18px solid #BFCE8D;
        margin: auto;
        margin-bottom: 70px;
    }
    .tituEstado{
        font-family: $suecaslabmedium;
        font-size: 16px;
        line-height: 22px;
        margin: 50px auto 0;
        text-align: center;
    }
    .nomEstado{
        font-family: $suecaslabbold;
        font-size: 24px;
        line-height: 30px;
        margin: 0 auto 40px;
        text-align: center;
        max-width: 640px;
    }
    .graf{
        margin: 50px auto 100px;
        position: relative;
        .tiempo{
            color: #fff;
            font-family: $suecaslabbold;
            font-size: 12px;
            line-height: 16px;
            text-align: center;
            position: absolute;
            z-index: 10;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: auto;
            span{
                font-size: 40px;
                line-height: 46px;
            }
            &.no{
                color: transparent;
                pointer-events: none;
            }
            &.noEs{
                color: transparent;
                pointer-events: none;
            }
            &.up{
                top: 26.5%;
            }
        }
        .circulo{
            margin: auto;
        }
        img[lazy='loading'] {
            opacity: 0.2;
        }
        img[lazy='loaded'] {
            opacity: 1;
            transition: opacity 0.2s;
        }
    }
    .tituH3{
        font-family: $suecaslabbold;
        font-size: 22px;
        line-height: 35px;
        margin: 40px auto 0;
        // color: #BFCE8D;
    }
    .tex{
        font-family: $suecaslabmedium;
        font-size: 18px;
        line-height: 25px;
        margin: 0px auto 40px;
        color: #676767;
        border-bottom: 1px solid #cacaca;
        padding: 10px 30px 60px;
        width: 90%;
        max-width: 850px;
    }
    .titusTabla{
        display: grid;
        grid-template-columns: 25% 22% 23% 20% 10%;
        width: 100%;
        max-width: 850px;
        margin: 20px auto 0;
        border-bottom: 1px solid #dedede;

        .tiTa{
            font-family: $suecaslabbold;
            font-size: 12px;
            line-height: 20px;
            text-align: left;
            padding: 10px;
            border-left: 1px solid #dedede;
            margin: 0;

            &:first-child{
                border-left: 1px solid transparent;
            }
        }
    }
    .tablaProy{
        width: 100%;
        max-width: 850px;
        margin: 0 auto 30px;
        padding: 0;
        // overflow: hidden;
        // height: 100px;
        // transition: all .5s ease;
        // // opacity: 0;
        // &.active {
        //     transition: all .5s ease;
        //     // opacity: 1;
        //     overflow: unset;
        //     height: auto;
        // }
        .proy{
            display: grid;
            grid-template-columns: 25% 22% 23% 20% 10%;
            border-bottom: 1px solid #dedede;

            .nom, .exped, .autor, .link, .vigente{
                width: 90%;
                font-family: $suecaslabmedium;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                padding: 10px;
                border-left: 1px solid #dedede;
                margin: 0;
            }
            .nom{
                border-left: 1px solid transparent;
            }
            .autor{
                cursor: default;
                pointer-events: none;
                width: fit-content;
                text-decoration: none;
                color: #000;
                &.ver{
                    cursor: pointer;
                    pointer-events: auto;
                    text-decoration: underline;
                }
            }
            .link{
                color: transparent;
                cursor: default;
                pointer-events: none;
                text-decoration: none;
                width: fit-content;
                &.ver{
                    color: #000;
                    cursor: pointer;
                    pointer-events: auto;
                    text-decoration: underline;
                }
            }
        }
    }
    .bot{
        width: fit-content;
        height: 30px;
        padding: 7px 20px;
        border: 1px solid #000;
        border-radius: 50px;
        margin: 10px auto 80px;
        transition: all .3s ease-out;
        cursor: pointer;
        display: none;
        p{
            font-family: $suecaslabmedium;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            margin: auto;
        }
        &:hover{
            background-color: #000;
            color: #fff;
        }
        &.active{
            display: block;
        }
    }
    .linkTextos{
        display: block;
        width: fit-content;
        font-family: $suecaslabbold;
        font-size: 18px;
        line-height: 20px;
        margin: 40px auto;
        color: #000;

        &.cober{
            margin: 80px auto 40px;
        }
    }
    #divLogos{
        #logos{
            width: 45%;
        }
    }
    // @media screen and (max-width: 1200px) {
    //     .graf{
    //         .tiempo{
    //             left: 46.5%;
    //         }
    //     }
    // }
    // @media screen and (max-width: 1100px) {
    //     .graf{
    //         .tiempo{
    //             left: 46%;
    //         }
    //     }
    // }
    @media screen and (max-width: 850px) {
        .encabeza {
            .tituTema{
                font-size: 32px;
            }
        }
        // .graf{
        //     .tiempo{
        //         left: 45.3%;
        //     }
        // }
        .titusTabla {
            width: 96%;
        }
        .tablaProy {
            width: 96%;
        }
    }
    @media screen and (max-width: 768px) {
        .encabeza {
            .desc{
                width: 90%;
            }
        }
        // .graf{
        //     .tiempo{
        //         left: 45%;
        //     }
        // }
        #divLogos{
            #logos{
                width: 60%;
            }
        }
    }
    @media screen and (max-width: 700px) {
        .nomEstado{
            font-size: 22px;
            line-height: 27px;
            width: 90%;
        }
        .tituH3{
            font-size: 20px;
            line-height: 28px;
            width: 90%;
        }
        .titusTabla {
            grid-template-columns: 25% 22% 23% 18% 12%;
            .tiTa{
                font-size: 11px;
            }
        }
        .tablaProy{
            .proy{
                grid-template-columns: 25% 22% 23% 18% 12%;
                .nom, .exped, .autor, .link, .vigente{
                    font-size: 12px;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .graf{
            .tiempo{
                // left: 42%;
                span{
                    font-size: 37px;
                    line-height: 40px;
                }
            }
        }
        .tex{
            padding: 10px 0 60px;
        }
        #divLogos{
            #logos{
                width: 70%;
            }
        }
    }
    @media screen and (max-width: 414px) {
        .encabeza {
            .tituTema{
                font-size: 29px;
                line-height: 35px;
            }
            .desc{
                font-size: 17px;
                line-height: 23px;
                margin: 20px auto 10px;
            }
        }
        .graf{
            .tiempo{
                font-size: 11px;
                line-height: 13px;
                // left: 41%;
                // top: 39%;
                &.up{
                    // left: 41%;
                    top: 27%;
                }
            }
            .circulo{
                width: 100%;
            }
        }
        .titusTabla {
            grid-template-columns: 24% 22% 21% 18% 15%;
            .tiTa{
                font-size: 10px;
                letter-spacing: -0.5px;
                line-height: 15px;
                padding: 5px;
                // padding-left: 5px;
                // transform: rotate(-90deg) translate(20px);
                // border-bottom: 1px solid #dedede;
                // border-left: none;
            }
        }
        .tablaProy{
            .proy{
                grid-template-columns: 24% 22% 21% 18% 15%;
                .nom, .exped, .autor, .link, .vigente{
                    padding-left: 5px;
                    line-height: 16px;
                }
            }
        }
        .linkTextos{
            font-size: 16px;
            line-height: 18px;
            margin: 25px auto;
        }
    }
    @media screen and (max-width: 375px) {
        .graf{
            .tiempo{
                // left: 40%;
                span{
                    font-size: 35px;
                    line-height: 38px;
                }
            }
        }
        .titusTabla {
            .tiTa{
                font-size: 9px;
                letter-spacing: -0.7px;
                line-height: 14px;
            }
        }
        .tablaProy{
            .proy{
                .nom, .exped, .autor, .link, .vigente{
                    font-size: 11px;
                    line-height: 15px;
                }
            }
        }
    }
    @media screen and (max-width: 350px) {
        .graf{
            .tiempo{
                line-height: 12px;
                // left: 38.5%;
                // top: 37%;
                span{
                    font-size: 30px;
                    line-height: 33px;
                }
                &.up{
                    top: 26%;
                    // left: 39%;
                }
            }
        }
    }
  }
</style>
