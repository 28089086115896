<template>
  <div id="camino">
    <div id="divCamino" :class="{ 'active': abre }">
      <div class="encabeza">
        <h2 class="tituTema">El camino de un proyecto de ley</h2>
        <p class="desc">Una vez que un proyecto de ley ingresó por la mesa de entrada de alguna de las dos cámaras,
          empieza a transitar por una serie de pasos que lo harán o no convertirse en ley. Conocé cómo es ese recorrido.
        </p>
      </div>
      <div id="vid"></div>
    </div>
    <div id="botCaminoLey" :class="{ 'active': abre }" @click="changeAbre">
      <p>EL CAMINO DE UNA LEY</p>
    </div>
  </div>
</template>

<script>
//import librerias from './librerias.js';
//   import Logos from './Logos.vue'
import bucketImage from '@/mixins/bucketImage.js'
import urlLn from '@/mixins/urlLn'
//   import Axios from 'axios'

//   const jsonTemas = 'https://s3.us-east-1.amazonaws.com/especiales.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'

export default {
  name: 'Camino',
  mixins: [bucketImage, urlLn],
  components: {
    //   Logos
  },
  mounted() {
    // this.fetchData();

    this.loadJwPlayer();
  },
  data() {
    return {
      abre: false,
      LnJwPlayerSteps: null
      // temas: null
    };
  },
  methods: {
    loadJwPlayer() {
      this.LnJwPlayerSteps = jwplayer("vid").setup({
        playlist: "https://cdn.jwplayer.com/v2/media/Mjsjy9Lf", // YVAHx0lb es el id del video esta en el admin
        controls: true,
        autostart: false,
        displaytitle: false,
        repeat: false,
        stretching: "fill",
        mute: false,
      });

    },

    changeAbre() {
      this.abre = this.abre === true ? false : true;
    },
    altoIframe() {
      let ancho = window.innerWidth
      let alto
      if (ancho <= 740) {
        alto = (ancho * 416) / 740
        return alto
      } else {
        return 416
      }
    },
    anchoIframe() {
      let ancho = window.innerWidth
      let anchoIframe
      if (ancho <= 500) {
        anchoIframe = "95%"
        return anchoIframe
      } else if (ancho <= 740) {
        anchoIframe = "98%"
        return anchoIframe
      } else {
        return 740
      }
    }
    //   fetchData(){
    //     Axios.get(jsonTemas)
    //     .then(response => {
    //         this.temas = response.data;
    //         console.log(response.data)
    //     })
    //     .catch(errors => {
    //         console.log(errors)
    //     })
    //   },
  }
}
</script>

<style lang="scss">
@import '@/styles/_global.scss';

#camino {
  width: 100%;
  margin: -30px auto 55px;
  text-align: center;

  #vid {
    width: 95%;
    max-width: 740px;
    margin: 20px auto;

  }

  #divCamino {
    background-color: #f2f2f2;
    width: 100%;
    margin: auto;
    overflow: hidden;
    height: 0;
    transition: all .5s ease;
    opacity: 0;

    &.active {
      transition: all .5s ease;
      opacity: 1;
      overflow: unset;
      height: auto;
      padding-bottom: 30px;
    }

    .encabeza {
      width: 100%;
      text-align: center;
      padding: 30px 0;

      .tituTema {
        font-family: $suecaslabbold;
        font-size: 20px;
        line-height: 25px;
        margin: 20px auto 0;
        color: #000;
        text-decoration: none;
      }

      .desc {
        font-family: $suecaslabmedium;
        font-size: 16px;
        line-height: 25px;
        margin: 0px auto 0px;
        padding: 10px 30px 30px;
        max-width: 740px;
      }
    }

    iframe {
      border: 1px solid #707070;
      margin: 0 auto 30px;
      // width: 740px;
      // height: 416px;
    }
  }

  #botCaminoLey {
    margin: auto;
    margin-top: -1px;
    width: fit-content;

    p {
      cursor: pointer;
      width: fit-content;
      padding: 25px 60px 20px;
      margin: auto;
      background-color: #000;
      color: #fff;
      text-align: center;
      font-family: $suecaslabbold;
      font-size: 13px;
      position: relative;
      -webkit-border-bottom-right-radius: 15px;
      -webkit-border-bottom-left-radius: 15px;
      -moz-border-radius-bottomright: 15px;
      -moz-border-radius-bottomleft: 15px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;

      &:before {
        content: '';
        position: absolute;
        left: calc(50% - 5px);
        top: 7px;
        width: 0;
        height: 0;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        border-top: 10px solid #fff;
        border-bottom: none;
      }

      &:hover {
        background-color: #707070;
      }
    }

    &.active {
      p {
        &:before {
          border-bottom: 10px solid #fff;
          border-top: none;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    #botCaminoLey {
      p {
        padding: 25px 35px 20px;
      }
    }
  }

  @media screen and (max-width: 414px) {
    #divCamino {
      .encabeza {
        .desc {
          padding: 10px 0px 30px;
          width: 90%;
        }
      }

      // iframe{
      //   width: 100%;
      // }
    }
  }
}
</style>
