<template>
  <div class="redes-ln" :class="theme">
    <div class="redes-wrapper">
      <a
        class="facebook-share-button"
        title="Compartilo en Facebook"
        @click="shareFB()"
      ></a>
      <a
        class="twitter-share-button"
        title="Compartilo en Twitter"
        @click="shareTW()"
      ></a>
      <a
        class="whatsapp-share-button"
        title="Compartilo por Whatsapp"
        @click="shareWH()"
      ></a>
    </div>
  </div>
</template>

<script>
////////////// redes //////////////
export default {
  name: 'Redes',
  props: {
    textoRedes: {
      required: true
    },
    hashtag: {
      required: false
    },
    isDark: {
      required: false,
      default: true
    }
  },
  data() {
    return {
      theme: {
        'btn-blancos': !this.isDark,
        'btn-negros': this.isDark
      }
    }
  },
  computed: {
    urlNota() {
      const urlNota = location.href
      return encodeURIComponent(urlNota)
    },
    notaId() {
      const startId = this.urlNota.indexOf('nid') + 3
      return this.urlNota.substring(startId)
    }
  },
  methods: {
    shareWH() {
      const urlShare = `https://api.whatsapp.com/send?text=${this.textoRedes} --> ${this.urlNota}`
      this.trackSocial('whatsapp')
      window.open(urlShare, '_blank')
    },
    shareTW() {
      const urlShare = `https://twitter.com/share?url=${this.urlNota}&text=${
        this.textoRedes
      }&hashtags=${this.hashtag || ''}&via=lanacion&size=large`
      this.trackSocial('twitter')
      window.open(urlShare, '_blank', 'width=555,height=400')
    },
    shareFB() {
      const urlShare = `https://www.facebook.com/sharer/sharer.php?u=${this.urlNota}`
      this.trackSocial('facebook')
      window.open(urlShare, '_blank', 'width=555,height=400')
    },
    trackSocial(socialNetwork) {
      const ev = {
        event: 'shareItem',
        socialAction: 'Share',
        socialNetwork
      }
      this.track(ev)
    },
    trackEvent(eventCategory, eventAction) {
      const ev = {
        event: 'trackEvent',
        eventCategory, // tipo de evento, ej: click
        eventAction,
        eventLabel: this.notaId
      }
      this.track(ev)
    },
    track(evObject) {
      if (!window.dataLayer) window.dataLayer = []
      window.dataLayer.push(evObject)
    }
  }
}
</script>

<style lang="scss">
#appContainer {
  /*redes*/

  .redes-ln {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 20px auto 30px;
    width: 90%;

    &.top {
      position: relative;
      margin: 0 auto;
      width: 192px;
    }

    @media (max-width: 640px) {
      // top: -3px;
      // right: calc(35% - 190px);
    }
    @media (max-width: 500px) {
      // top: -3px;
      // right: calc(42% - 190px);
    }
    @media (max-width: 400px) {
      // top: -3px;
      // right: calc(52% - 190px);
    }

    a {
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      margin: 0 0.4rem;
      background-repeat: no-repeat;
      background-size: 140px;
      border: 1px solid #000;

      &.facebook-share-button {
        background-position: 0px -42px;
      }

      &.twitter-share-button {
        background-position: -34px -42px;
      }

      &.whatsapp-share-button {
        background-position: -70px -43px;
      }
    }
    a:hover {
      opacity: 0.5;
    }
  }

  .redes-ln.btn-blancos a {
    // background-color: white;
    // filter: hue-rotate(100deg) invert(0.8);
    background-image: url(https://buckets3.glanacion.com/lntools/anexos/fotos/15/15415.svg);
    opacity: 1;
    background-color: white;
    transition: all 200ms;
    &:hover {
      opacity: 0.6;
    }
  }
  .redes-ln.btn-negros a {
    background-color: black;
    background-image: url(https://buckets3.glanacion.com/lntools/anexos/fotos/16/15416.svg);
    opacity: 1;
    border-color: white;
    transition: all 200ms;
    &:hover {
      opacity: 0.6;
    }
  }
}
</style>
