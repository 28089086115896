<template>
  <div id="estados">
    <!-- <p id="intro">Acá va la descripción</p> -->
    <div v-for="(item, index) in temasOrden" :key="index" class="tema">
        <a class="ir" :href="ids(item)">
            <div class="encabeza">
                <h2 class="tituTema">{{ item.nombre }}</h2>
            </div>
            <img
                class="circulo lazy"
                v-lazy="circulosGrid(item)"
                alt="Referencias del estado legislativo"
            />
        </a>
    </div>
  </div>
</template>

<script>
//   import librerias from './librerias.js';
//   import Logos from './Logos.vue'
  import bucketImage from '@/mixins/bucketImage.js'
  import urlLn from '@/mixins/urlLn'
//   import VueLazyload from 'vue-lazyload'
//   import Axios from 'axios'

//   const jsonTemas = 'https://s3.us-east-1.amazonaws.com/especiales.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'
//   const jsonTemas = 'https://especialess3.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'

  export default {
    name: 'Estados',
    mixins: [bucketImage, urlLn],
    props: ["temas", "seleccionado"],
    // mounted() {
    //     this.fetchData();
    // },
    // data(){
    //     return {
    //         sel: this.seleccionado
    //     };
    // },
    computed: {
        temasOrden() {
            let ordenado = Object.values(this.temas)
            if (this.seleccionado !== "TODOS LOS TEMAS") {
                ordenado = ordenado.filter(o => o.nombre == this.seleccionado)
            }
            return ordenado.sort(function(a, b) {
                if (parseInt(a.estadoNumero) > parseInt(b.estadoNumero)) {
                    return -1;
                }
                if (parseInt(a.estadoNumero) < parseInt(b.estadoNumero)) {
                    return 1;
                }
                return 0;
            });
        }
    },
    methods: {
        selectBusca(item) {
            // let selecCorto = item.replace(/[áéíóúüñ-\s\(\)\(.)\(+)\(*)\(,)/]/g, '').toLowerCase();
            // console.log(selecCorto, this.sel)
            if (item) { // NO VA A ENTRAR ACA
            console.log("EEEE", item.nombre, this.seleccionado)
                return true
            }
        },
        circulosGrid(item) {
            let ancho = window.innerWidth
            let imag
            // this.temas.forEach(element => {
                // console.log("ANTES", item.estadoNumero)
            if (item.estadoNumero == 100 || item.estadoNumero == 22) {
                item.estadoNumero = 12
            } else if (item.estadoNumero == 15) {
                item.estadoNumero = 14
            } else {
                item.estadoNumero = item.estadoNumero
            }
                // console.log("DESP", item.estadoNumero)
            if (ancho > 640) {
                imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/estados/" + item.estadoNumero + "_DESKTOP_GRID.svg?v=2"
                return imag
            } else {
                imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/estadosMobile/" + item.estadoNumero + "_MOBILE_GRID.svg?v=2"
                return imag
            }
            // console.log(imag)
            // // }); 
            // return imag
        },
        ids(item){
            let nom = item.nombre
            let nomClass = nom.replace(/[áéíóúüñ-\s\(\)\(.)\(+)\(*)\(,)/]/g, '').toLowerCase();
            return "#" + nomClass
        },
    }
  }
</script>

<style lang="scss">
  @import '@/styles/_global.scss';

  #estados {
    width: 100%;
    max-width: 1260px;
    margin: 20px auto 200px;
    text-align: center;
    display: grid;
    grid-template-columns: repeat(4, 24%);
    grid-template-rows: auto;
    gap: 20px 1%;
    .tema{
        width: 100%;
        margin: auto;
        // place-self: center;
        .ir{
            color: #000;
            text-decoration: none;
            .encabeza {
                width: 100%;
                //   background-color: #BFCE8D;
                text-align: center;
                padding: 30px 0 10px;
                .tituTema{
                    font-family: $suecaslabbold;
                    font-size: 15px;
                    line-height: 20px;
                    margin: 20px auto 0;
                    padding: 0;
                    color: #000;
                    text-decoration: none;
                    width: 85%;
                    min-height: 75px;
                }
            }
            .circulo{
                width: 90%;
                height: 90%;
                object-fit: contain;
                margin: auto;
            }
            img[lazy='loading'] {
                opacity: 0.2;
                // width: 20%;
            }
            img[lazy='loaded'] {
                opacity: 1;
                // width: 95%;
                transition: all 0.2s ease-out;
            }
        }
    }
    .tituH3{
        font-family: $suecaslabbold;
        font-size: 22px;
        line-height: 35px;
        margin: 40px auto;
        color: #BFCE8D;
    }
    .tex{
        font-family: $suecaslabmedium;
        font-size: 18px;
        line-height: 25px;
        margin: 20px auto 40px;
        color: #676767;
        border-bottom: 1px solid #707070;
        padding: 10px 30px 30px;
        width: 850px;
    }
    .titusTabla{
        display: grid;
        grid-template-columns: 25% 22% 23% 20% 10%;
        width: 850px;
        margin: 20px auto 0;
        border-bottom: 1px solid #dedede;

        .tiTa{
            font-family: $suecaslabbold;
            font-size: 12px;
            line-height: 20px;
            text-align: left;
            padding: 10px;
            border-left: 1px solid #dedede;
            margin: 0;

            &:first-child{
                border-left: 1px solid transparent;
            }
        }
    }
    .tablaProy{
        width: 850px;
        margin: 0 auto 80px;
        padding: 0;
        .proy{
            display: grid;
            grid-template-columns: 25% 22% 23% 20% 10%;
            border-bottom: 1px solid #dedede;

            .nom, .exped, .autor, .link, .vigente{
                width: 90%;
                font-family: $suecaslabmedium;
                font-size: 14px;
                line-height: 20px;
                text-align: left;
                padding: 10px;
                border-left: 1px solid #dedede;
                margin: 0;
            }
            .nom{
                border-left: 1px solid transparent;
            }
        }
    }
    .linkTextos{
        display: block;
        width: fit-content;
        font-family: $suecaslabbold;
        font-size: 18px;
        line-height: 20px;
        margin: 40px auto;
        color: #000;
    }
    #divLogos{
        width: 500px;
    }
    @media screen and (max-width: 850px) {
        grid-template-columns: repeat(3, 32%);
        width: 99%;
    }
    @media screen and (max-width: 500px) {
        max-width: unset;
        grid-template-columns: repeat(2, 50%);
        gap: 0;
        margin: 50px auto 200px;
        .tema{
            width: 100%;
            padding: 0 0 20px;
            &:nth-child(even){
                border-bottom: 1px solid #dcdcdc;
                // border-right: 1px solid #dcdcdc;
            }
            &:nth-child(odd){
                border-right: 1px solid #dcdcdc;
                border-bottom: 1px solid #dcdcdc;
            }
            .ir{
                .encabeza {
                    padding: 15px ​0 10px;
                    .tituTema{
                        margin: 0px auto;
                        min-height: 65px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
  }
</style>
