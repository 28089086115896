export default {
  methods: {
    /**
     * @name  urlLn
     * @param  {string} type - Type indicar si es 'aml' o 'spread'
     * @param  {string} id - Id del gdoc si es type 'aml' o del spreadsheet si es type 'spread'
     * @param  {string} [gid=0] - Id de la pestaña si es type 'spread' con gid distinto de 0
     */
    urlLn(type, id, gid) {
      if (type === 'aml')
        return `https://archieml.lanacion.com.ar/archiemldocs?doc_id=${id}`
      else
        return `https://olcreativa.lanacion.com.ar/dev/get_url/api.php?key2=${id}&gid=${
          gid ? gid : 0
        }`
    }
  }
}
