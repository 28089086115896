import Vue from 'vue';
import App from './App.vue';
//import librerias from './librerias.js';
import VueLazyload from 'vue-lazyload'

Vue.config.productionTip = false
Vue.use(VueLazyload)

Vue.use(VueLazyload, {
  preLoad: 1.3
})

new Vue({
  render: h => h(App),
}).$mount('#app')
