<template>
  <div id="metodologia">
    <div class="glosa" :class="{'active':abreGlosa}">
      <h3>Glosario</h3>
      <div class="abreGlosa" :class="{'active':abreGlosa}" @click="changeAbreGlosa">{{ textAbre }}</div>
      <p v-for="(item, index) in glosario" :key="index">
        <span class="termino">- {{ item.termino }}</span>: <span class="significado">{{ item.significado }}</span>
      </p>
    </div>
    <div class="metodo">
      <h3>Metodología</h3>
      <p>
        Este monitor tiene como objetivo hacer un seguimiento del avance de los temas ambientales más importantes que se están debatiendo o esperan ser tratados en el Congreso, desde que ingresan a alguna de las Cámaras hasta convertirse en ley. <br>Se actualiza en base a información relevada por las organizaciones Directorio Legislativo, FARN, Fundación Vida Silvestre Argentina y Eco House en alianza con LA NACION. En una primera etapa, la propuesta se concentrará en algunos ejes temáticos, para luego ir ampliándose. Los temas fueron elegidos por la urgencia de que se conviertan en ley.
      </p>
    </div>
    <div class="consulta">
      <p>Escribí tu consulta a:</p>
      <a href="mailto:lnData@lanacion.com.ar">LNdata@lanacion.com.ar</a>
    </div>
  </div>
</template>

<script>
  //   import Redes from './Redes.vue'
  import Axios from 'axios'

  let txtGlosario = 'https://olcreativa.lanacion.com.ar/dev/get_url/api.php?key2=1z4Cn9n-LhfJb_dPeRQXQZuskYxl4t8QPTcV6PoDs-1w&gid=170017605'

  export default {
    name: 'Metodologia',
    data() {
      return {
        glosario: null,
        abreGlosa: false,
        textAbre: "ABRIR"
      };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
      fetchData(){
        let requestDatos1 = Axios.get(txtGlosario);
        Axios.all([requestDatos1])
        .then(
          Axios.spread((...responses) => {
            this.glosario = responses[0].data;
          })
        )
        .catch(errors => {
          // react on errors.
          console.log(errors);
        });
      },
      changeAbreGlosa() {
        this.abreGlosa = this.abreGlosa === true ? false : true;
        this.textAbre = this.textAbre === "ABRIR" ? "CERRAR" : "ABRIR"
      }
    }
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/styles/_variables.scss';
  #metodologia {
    width: 100%;
    max-width: 960px;
    margin: 50px auto;
    .metodo, .glosa {
      margin: 10px auto 20px;
      text-align: center;
      background-color: #f0f0f0;
      color: #000;
      padding: 40px;
      border-radius: 10px;
      h3 {
        font-family: $suecaslabbold;
        font-size: 1.5rem;
        margin: 0 auto 20px;
      }
      p{
        // text-align: left;
        font-size: .9rem;
        line-height: 1.2rem;
      }
    }
    .glosa{
      // text-align: left;
      background-color: transparent;
      border: 1px solid #959595;
      overflow: hidden;
      height: 140px;
      transition: all .5s ease;
      &.active {
        transition: all .5s ease;
        overflow: unset;
        height: auto;
      }
      .abreGlosa{
        width: fit-content;
        height: 30px;
        padding: 5px 20px 7px;
        border: 1px solid #000;
        border-radius: 50px;
        margin: 10px auto 30px;
        transition: all .3s ease-out;
        cursor: pointer;
        font-family: $suecaslabmedium;
        font-size: 12px;
        line-height: 18px;
        &:hover{
          background-color: #000;
          color: #fff;
        }
      }
      p{
        text-align: left;
        font-size: .8rem;
        line-height: 1.1rem;
        margin: 5px auto;
        // display: inline;
          font-family: $suecaslabsemibold;
        .termino{
          text-transform: uppercase;
          font-size: .75rem;
        }
        .significado{
          font-family: $suecaslabmedium;
        }
        // .barra{
        //   &:last-child{
        //     display: none;
        //   }
        // }
      }
    }
    .consulta {
      text-align: center;
      width: 100%;
      max-width: 740px;
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      margin: 20px auto;
      padding: 20px 0;
      p {
        font-family: $suecaslab;
        font-size: 1rem;
        margin: 0 auto 20px;
      }
      a {
        font-family: $suecaslabbold;
        font-size: 1.2rem;
        color: #000;
        text-decoration: none;
        margin: 20px auto;
      }
    }
    @media screen and (max-width: 750px) {
      .metodo, .glosa {
        width: 95%;
        padding: 30px 0;
        h3 {
          margin: 0 auto 10px;
        }
        p{
          margin: auto;
          width: 90%;
        }
      }
    }
    @media screen and (max-width: 500px) {
      .metodo, .glosa {
        width: 90%;
        padding: 30px 10px;
        p{
          margin: auto;
          width: 90%;
          font-size: .9rem;
        }
      }
      .glosa{
        height: 125px;
      }
    }
  }
</style>
