<template>
  <div id="appCabezal">
    <h1>MONITOR LEGISLATIVO AMBIENTAL</h1>
    <h2>Qué tan cerca están los proyectos de convertirse en ley</h2>
  </div>
</template>

<script>
  export default {
    name: 'Cabezal',
    props: {
      //msg: String
    },
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  @import '@/styles/_variables.scss';
  #appCabezal {
    margin: 0 auto 30px;
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 200px 0 70px;
    h1 {
      margin: 0 auto 10px;
      font-family: $suecaslabbold;
      position: relative;
      &:before {
        content: '';
        width: 85px;
        height: 85px;
        position: absolute;
        top: -100px;
        left: calc(50% - 30px);
        // background-image: url('https://buckets3.glanacion.com/lntools/anexos/fotos/80/32180.svg');
        background-image: url('~@/assets/icono-titulo.svg');
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    h2 {
      margin: 10px ​auto 20px;
      font-family: $suecaslabthin;
      font-size: 18px;
      text-transform: uppercase;
    }
    @media screen and (max-width: 850px) {
      h1{
        font-size: 40px;
        width: 90%;
      }
      h2{
        font-size: 23px;
        width: 90%;
      }
    }
    @media screen and (max-width: 640px) {
      h1{
        font-size: 35px;
      }
      h2{
        font-size: 22px;
      }
    }
  }
</style>
