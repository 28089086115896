<template>
    <div id="botSubir">
    <a href="#estados" class="bot">subir</a>
    </div>
</template>

<script>
////////////// redes //////////////
export default {
  name: 'Redes',
//   props: {
//     textoRedes: {
//       required: true
//     },
//     hashtag: {
//       required: false
//     },
//     isDark: {
//       required: false,
//       default: true
//     }
//   },
//   data() {
//     return {
//       theme: {
//         'btn-blancos': !this.isDark,
//         'btn-negros': this.isDark
//       }
//     }
//   },
  computed: {
    urlNota() {
      const urlNota = location.href
      return encodeURIComponent(urlNota)
    },
    notaId() {
      const startId = this.urlNota.indexOf('nid') + 3
      return this.urlNota.substring(startId)
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
  @import '@/styles/_global.scss';

    #botSubir {
        position: fixed;
        right: 30px;
        bottom: 25px;
        background-color: #fff;
        border-radius: 50%;
        .bot{
            display: inline-block;
            width: 85px;
            height: 85px;
            cursor: pointer;
            border-radius: 50%;
            margin: auto;
            text-decoration: none;
            background-repeat: no-repeat;
            background-size: 140px;
            border: 1px solid #707070;
            font-family: $suecaslabsemibold;
            font-size: 15px;
            line-height: 90px;
            color: #5F5F5F;
            position: relative;
            &:before, &:after {
                content: '';
                height: 10px;
                position: absolute;
                -moz-transform: rotate(45deg);
                -o-transform: rotate(45deg);
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                top: 22px;
                width: 0;
                border-right: 2px solid;
            }
            &:before {
                left: calc(50% - 3px);
            }
            &:after {
                left: calc(50% + 3px);
                -moz-transform: rotate(-45deg);
                -o-transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
        }
        a:hover {
            opacity: 0.5;
        }
        @media (max-width: 640px) {
            // top: -3px;
            // right: calc(35% - 190px);
        }
        @media (max-width: 500px) {
            // top: -3px;
            // right: calc(42% - 190px);
        }
        @media (max-width: 400px) {
            // top: -3px;
            // right: calc(52% - 190px);
        }
    }
</style>
