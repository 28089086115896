<template>
  <!-- <select v-model="seleccionado">
        <option
          v-for="(items, index) in searching"
          :value="items"
          :key="index"
        >
          {{ items }}
        </option>
      </select> -->
    <div class="searchTema">
        <v-select
        transition=""
        :options="searchTema(temas)"
        label="nombre"
        v-model="seleccionado"
        >
        <template #no-options="{ search, searching, loading }"
            >No hay resultados</template
        >
        </v-select>
    </div>
</template>

<script>
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

export default {
    props: ["temas"],
    data() {
        return {
            // datos: null,
            seleccionado: 'TODOS LOS TEMAS'
        }
    },
    components: {
        vSelect
    },
    computed: {
    },
    methods: {
        searchTema(temas) {
            let temasBusca = Object.values(temas)
            let tem = []
            if (temas) {
                for(let i = 0; i < temasBusca.length; i++) {
                    tem.push(temasBusca[i].nombre)
                }
                tem.sort(function (a, b) {return a.localeCompare(b);})
                tem.unshift('TODOS LOS TEMAS')
                return tem
            } else {
                return []
            }
        }
    },
    watch: {
        seleccionado (newVal, oldVal) {
            this.$emit('seleccionado', newVal)
        }
    }
};
</script>

<style>
input {
  font-size: 16px;
 
}
.searchTema {
  width: 350px;
  height: 35px;
  font-size: 0.8em;
  margin: 20px auto 30px;
  border-radius: 50%;
  /* 
  border: 1px solid #dadada;
  border-radius: 50px;
  background: url(~@/assets/busca.svg) no-repeat 95% center #fff;
  background-size: 30px;
  position: relative;
  cursor: pointer;
  color: #000;
  text-align: left; */
}
/* .vue-dynamic-select {
  min-width: 200px;
  border: 1px solid #ced4da;
  position: relative;
  padding: 0.375em 1em;
  border-radius: 50px;
  height: 27px;
  cursor: text;
  display: block;
  cursor: pointer;
} */
.vue-dynamic-select i.dropdown,
.vue-dynamic-select i.dropup {
  display: none;
}
/* .vue-dynamic-select i.dropdown,
.vue-dynamic-select i.dropup {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  float: right;
  opacity: 0.8;
  cursor: pointer;
  position: relative;
  top: 0.5em;
  right: 5px;
} */
.vue-dynamic-select i.dropup {
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: none;
  border-bottom: 5px solid;
}
.vue-dynamic-select .placeholder {
  display: inline-block;
  color: #000;
  padding-top: 4px;
  text-transform: uppercase;
  line-height: 27px;
  text-align: left !important;
  font-size: .7rem;
  margin-left: 20px;
  width: 80%;
}
.vue-dynamic-select .result-list {
  border: 1px solid #ced4da;
  margin: calc(0.375em - 1px) calc(-1em - 1px);
  width: calc(97% + 2px);
  min-width: calc(97% + 2px);
  border-radius: 0 0 0.25em 0.25em;
  cursor: pointer;
  position: absolute;
  z-index: 10;
  background-color: #fff;
  top: 31px;
}
.vue-dynamic-select .result-list .result {
  padding: 0.375em 0.75em;
  color: #333;
}
.vue-dynamic-select .result-list .result:hover,
.vue-dynamic-select .result-list .result:focus {
  background-color: #efefef;
  outline: none;
  color: #000;
}
.vue-dynamic-select .selected-option {
  display: inline-block;
  line-height: 33px;
  color: #000;
  /* overflow: hidden; */
}
.vue-dynamic-select .search {
  border: none;
  background-color: transparent;
  width: 80%;
  margin: 4px auto;
  margin-left: 20px;
  line-height: 27px;
  color: #000;
}
.vue-dynamic-select .search:focus {
  outline: none;
  color: #000;
}
.leaflet-touch.leaflet-bar {
  margin-top: 50px !important;
}
.vs--searchable .vs__dropdown-toggle{
    border-radius: 50px;
    width: 350px;
    height: 35px;
}
.vs__selected-options{
    height: 35px;
    padding: 7px 2px;
}
.vs__selected{
    margin: -3px 10px 0;
}
.vs__selected{
    margin: 0px 10px;
}
.vs--single.vs--open .vs__selected{
    position: absolute;
    top: 8px;
}
@media screen and (max-width: 800px) {
  .searchTema {
    width: 305px;
  }
    .vs--searchable .vs__dropdown-toggle{
        width: 305px;
    }
}
@media screen and (max-width: 600px) {
  .searchTema {
    width: 280px;
  }
  .vs--searchable .vs__dropdown-toggle{
    width: 280px;
  }
}
@media screen and (max-width: 550px) {
  .vue-dynamic-select .placeholder {
    padding-top:0;
  }
}
@media screen and (max-width: 414px) {
  .vue-dynamic-select {
    height: 23px;
    /* padding: 0 1em 0.375em; */
  }
  .vue-dynamic-select .placeholder {
    font-size: 11px;
    line-height:31px;
  }
}
</style>