export default [{
    categoria: "Coordinación general",
    personas: [
      "Florencia Coelho (@fcoel)",
      "Natalia Louzau (@natilouz)"
    ]
  },
  {
    categoria: "Edición de contenidos",
    personas: [
      "Evangelina Bucari (@evabucari)",
      "Florencia Rodríguez Altube (@FlorRAltube)"
    ]
  },
  {
    categoria: "Producción y actualización de contenidos",
    personas: [
      "Andrés Napoli",
      "Ana Di Pangracio",
      "Carolina Noya",
      "Daniela Gomel",
      "Federico Merino",
      "Leandro Domínguez",
      "Lucía Martínez Lima",
      "María Aguilar",
      "Máximo Mazzocco",
      "Paula Lértora"
    ]
  },

  {
    categoria: "Diseño y programación",
    personas: [
      "Florencia Abd (@florenabd)",
      "Nicolás Rivera (@dudenico)",
      "Gabriel Alonso Quiroga (@gbr_alonso)",
      "Martín Pascua (@MartinPascuaDev)"
    ]
  },

  {
    categoria: "Producción audiovisual",
    personas: [
      "Carolina Nussbaum (@CaroNussbaum)"
    ]
  }
]
