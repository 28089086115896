<template>
  <div id="copyright">
    Copyright {{ anio }} - SA LA NACION | Todos los derechos reservados
  </div>
</template>

<script>

export default {
  name: 'Copyright',
  data(){
      return {
          anio: 0
      }
  },
  props: {
    //msg: String
  },
  mounted: function () {
      this.getAnio();
  },
  methods: {
      getAnio(){
          this.anio = new Date().getFullYear();
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "@/styles/_variables.scss";

#copyright{
    color:black;
    font-size: 12px;
    font-family: $suecaslab;
    margin: 8px 0;
    padding-top:20px;
}
</style>
