<template>
  <div id="app">
    <Header />
    <!-- OJO! este header no es CEO Friendly -->
    <CaminoLey />
    <Redes
      :textoRedes="'Monitor Legislativo Ambiental: ¿qué tan cerca están los proyectos de convertirse en ley? Una plataforma para seguir el pulso de la agenda ambiental en el Congreso. @LNnaturaleza @fundlanacion'"
      :hashtag="'MonitorLegislativoAmbiental'"
      :isDark="true"
    ></Redes>
    <p id="intro">
      Casi la mitad del plástico se usa en productos que tienen una vida útil de segundos. Los ecosistemas que previenen inundaciones no se protegen lo suficiente. La pesca ilegal o la caza de especies amenazadas no encuentran mayores obstáculos. Y el 24% de las especies de mamíferos está bajo amenaza. Estos son apenas algunos de los temas ambientales que preocupan y aguardan con urgencia la sanción de leyes para ser abordados.<br><br>
Si bien la Argentina cuenta con un amplio marco normativo que protege al ambiente, todavía hay aspectos no regulados al tiempo que parte de la legislación existente requiere actualizaciones para garantizar las herramientas necesarias para resolver estas problemáticas. Hay muchos proyectos de ley que ya fueron debatidos en numerosas oportunidades, pero sin éxito, mientras que otros son sistemáticamente cajoneados y aguardan ser tenidos en cuenta por los legisladores.<br><br>
Este monitor se propone hacer un seguimiento del avance de los temas ambientales que están dentro del Congreso y que buscan –y necesitan– convertirse en ley. Es un aporte al acceso a la información, a través de una propuesta sencilla, que visibiliza el recorrido para contar con esta legislación.
    </p>
    <Logos />
    <h2>ESTADO LEGISLATIVO: <br>EL AVANCE DE TODOS LOS PROYECTOS</h2>
    <img
      id="refe"
      :src= refes()
      alt="Referencias del estado legislativo"
    />
    <SearchBusca @seleccionado="setSeleccionado" v-bind:temas="temas"/>
    <Estados v-bind:temas="temas" :seleccionado="seleccionado"/>
    <DescripcionTema v-bind:temas="temas"/>
    <!-- <img
      id="refe"
      src="https://buckets3.glanacion.com/lntools/anexos/fotos/91/32191.svg"
      alt="Referencias del estado legislativo"
    /> -->
    <!-- <FooterModal/> -->
    <Metodologia />
    <FooterStatic />
    <BotSubir />
  </div>
</template>

<script>
  //import librerias from './librerias.js';
  import Header from './components/Header.vue'
  import Logos from './components/Logos.vue'
  import CaminoLey from './components/CaminoLey.vue'
  import Redes from './components/Redes.vue'
  import SearchBusca from './components/SearchBusca.vue'
  import Estados from './components/Estados.vue'
  import BotSubir from './components/BotSubir.vue'
  import DescripcionTema from './components/DescripcionTema.vue'
  import Metodologia from './components/Metodologia.vue'
  // import FooterModal from './components/Footer-modal.vue'
  import FooterStatic from './components/Footer-static.vue'
  import bucketImage from '@/mixins/bucketImage.js'
  import urlLn from '@/mixins/urlLn'
  import Axios from 'axios'

  const jsonTemas = 'https://s3.us-east-1.amazonaws.com/especiales.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'
  // const jsonTemas = 'https://especialess3.lanacion.com.ar/monitor_ambiental_legislativo/jsons/temas-progreso-legislativo.json'

  export default {
    name: 'app',
    mixins: [bucketImage, urlLn],
    components: {
      Header,
      Logos,
      CaminoLey,
      Redes,
      SearchBusca,
      Estados,
      BotSubir,
      DescripcionTema,
      Metodologia,
      // FooterModal,
      FooterStatic
    },
    data() {
        return {
            temas: {},
            seleccionado: "TODOS LOS TEMAS"
        };
    },
    mounted() {
        this.fetchData();
        this.hideLoader()
    },
    methods: {
      fetchData(){
        Axios.get(jsonTemas)
        .then(response => {
            this.temas = response.data;
        })
        .catch(errors => {
            console.log(errors)
        })
      },
      setSeleccionado(value) {
        this.seleccionado = value
      },
      refes() {
        let ancho = window.innerWidth
        let imag
        if (ancho > 640) {
          // imag = "@/assets/masMenosLejos.svg"
          imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/masMenosLejos.svg"
          return imag
        } else {
          // imag = "@/assets/masMenosLejosMob.svg"
          imag = "https://especialess3.lanacion.com.ar/21/05/monitor-legislativo-ambiental-front/img/masMenosLejosMob.svg"
          return imag
        }
      },
      hideLoader() {
        document.getElementById('preload').classList.add('loaded')
        document.getElementById('appContainer').classList.add('loaded')
      }
    }
  }
</script>

<style lang="scss">
  @import '@/styles/_global.scss';
#fusion-app>#wrapper{
  margin-top: 0;
}
  #app {
    margin: auto;
    text-align: center;
    
    #intro {
      text-align: center;
      width: 100%;
      max-width: 740px;
      font-family: $suecaslab;
      font-size: 17px;
      line-height: 25px;
      margin: 40px auto 70px;
    }
    h2 {
      font-size: 25px;
      font-family: $suecaslabsemibold;
      text-align: center;
      margin: auto;
      br{
        display: none;
      }
    }
    #refe {
      text-align: center;
      width: 100%;
      max-width: 740px;
      margin: 10px auto !important;
    }
    @media screen and (max-width: 768px) {
      #intro {
        width: 90%;
      }
      h2{
        font-size: 22px;
        width: 90%;
        br{
          display: inline;
        }
      }
    }
    @media screen and (max-width: 640px) {
      #refe {
        width: 70%;
      }
    }
    @media screen and (max-width: 500px) {
      h2{
        font-size: 20px;
      }
    }
    @media screen and (max-width: 414px) {
      #refe {
        width: 80%;
      }
    }
    @media screen and (max-width: 350px) {
      #refe {
        width: 90%;
      }
    }
  }
</style>
